import './App.css';
import { useState } from 'react';
import fivefates from "../src/images/fivefates.png"
import dawn from "../src/images/dawn17_sin_sombra.png"
import rebellion from "../src/images/rebellion19_sin_sombra.png"
import courage from "../src/images/courage26_sin_sombra.png"
import honor from "../src/images/honor28_sin_sombra.png"
import hope from "../src/images/hope21_sin_sombra.png"

function App() {
  const [isShown1, setIsShown1] = useState(true);
  const [isShown2, setIsShown2] = useState(true);
  const [isShown3, setIsShown3] = useState(true);
  const [isShown4, setIsShown4] = useState(true);
  const [isShown5, setIsShown5] = useState(true);

const hover1 = () =>{
  setIsShown2(false)
  setIsShown3(false)
  setIsShown4(false)
  setIsShown5(false)
}
const unhover1 = () =>{
  setIsShown2(true)
  setIsShown3(true)
  setIsShown4(true)
  setIsShown5(true)
}

const hover2 = () =>{
  setIsShown1(false)
  setIsShown3(false)
  setIsShown4(false)
  setIsShown5(false)
}
const unhover2 = () =>{
  setIsShown1(true)
  setIsShown3(true)
  setIsShown4(true)
  setIsShown5(true)
}

const hover3 = () =>{
  setIsShown1(false)
  setIsShown2(false)
  setIsShown4(false)
  setIsShown5(false)
}
const unhover3 = () =>{
  setIsShown2(true)
  setIsShown1(true)
  setIsShown4(true)
  setIsShown5(true)
}

const hover4 = () =>{
  setIsShown2(false)
  setIsShown3(false)
  setIsShown1(false)
  setIsShown5(false)
}
const unhover4 = () =>{
  setIsShown2(true)
  setIsShown3(true)
  setIsShown1(true)
  setIsShown5(true)
}

const hover5 = () =>{
  setIsShown2(false)
  setIsShown3(false)
  setIsShown4(false)
  setIsShown1(false)
}
const unhover5 = () =>{
  setIsShown2(true)
  setIsShown3(true)
  setIsShown4(true)
  setIsShown1(true)
}
 

  return (
    <div className = "container">
    <div className='hoverContainer'>
      <div className="hoverer1" onMouseEnter={hover1} onMouseLeave = {unhover1}></div>
      <div className="hoverer1" onMouseEnter={hover2} onMouseLeave = {unhover2}></div>
      <div className="hoverer1" onMouseEnter={hover3} onMouseLeave = {unhover3}></div>
      <div className="hoverer1" onMouseEnter={hover4} onMouseLeave = {unhover4}></div>
      <div className="hoverer1" onMouseEnter={hover5} onMouseLeave = {unhover5}></div>
    </div>
      <div className = "backgroundImage">
        <img className ="back" src={fivefates} alt="fivefates"/>
        {isShown1 && <img className ="fate1" src={dawn} alt="Dawn17"/>}
        {isShown2 && <img className ="fate2" src={rebellion} alt="rebellion19"/>}
        {isShown3 && <img className ="fate3" src={courage} alt="courage26"/>}
        {isShown4 && <img className ="fate4" src={honor} alt="honor28"/>}
        {isShown5 && <img className ="fate5" src={hope} alt="hope21"/>}
      </div>
    </div>
  );
}

export default App;
